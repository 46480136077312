import { History } from "history";
import {ApplicationContext, IApplicationContext} from "infrastructure/contexts/contexts";
import React, {useContext, useEffect} from "react";
import {find, get} from "lodash";
import {marvinUITheme} from "infrastructure/contexts/deprecated";
import {LanguageContext} from "localization/components/Languages";
import {getCultureName} from "localization/utils";
import {applicationHistory} from "../../infrastructure/ApplicationCore";
import {useAsRef} from "../../hooks/useAsRef";
import {Spinner} from "../Loading/Spinner";
import {mountModule, unmountModule} from "./mount";

type Props = {
	/**
	 * module code
	 */
	code: string;

	history: History;
}

export function ModuleLoader({ code, history }: Props) {
	const context = useAsRef(useContext<IApplicationContext>(ApplicationContext));

	const languages = useContext(LanguageContext);

	// select module language
	const language = get(languages.modules, code, languages.core);

	// todo refactor
	// 	I have no idea why this is here... was part of the original code, but it won't work without it...
	// 	the whole browser history/location mess should be refactored anyway
	if (history.action !== "POP") {
		applicationHistory.push({
			pathname: history.location.pathname,
			search: history.location.search,
		});
	}

	// on language change (or mount) reload module (stupid, but can't be change now due to module dependency and legacy support)
	useEffect(() => {
		const module = find(context.current.marvinConfiguration.modules, m => m.code?.toLowerCase() === code?.toLowerCase())!;

		let canceled = false;

		const moduleContext = {
			history: applicationHistory,
			onModuleLoad: context.current.onModuleLoad,
			getService: context.current.getService,
			configuration,
			moduleConfiguration: module,
			settings: { cultureCode: language },
			cultures: module.cultures.map((code, index) => ({
				code: code,
				isDefault: index === 0,
				name: getCultureName(code)
			})),
			// legacy stuff
			isMobile: false,
			concernThemeVariables: {},
			theme: marvinUITheme()
		};

		mountModule(code, moduleContext, () => canceled)
			// eslint-disable-next-line no-console
			.then(() => console.debug(`Module ${code} has been loaded`))
			// eslint-disable-next-line no-console
			.catch(() => console.error(`Failed to load module ${code}`))

		return () => {
			canceled = true;
			// eslint-disable-next-line no-console
			console.debug(`Module ${code} is being unloaded`)
			unmountModule(code);
		}
	}, [language]);

	// todo could use a nice loading indicator (while script and data are being is loading)
	return (
		<div id={code} style={{ flex: 1, overflow: "hidden", display: "flex" }}>
			<Spinner />
		</div>
	);
}
