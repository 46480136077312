import {History} from "history";
import {useEffect} from "react";
import { useHistory } from "react-router-dom"
import {Spinner} from "../Loading/Spinner";
import {IModuleConfiguration} from "../../@marvin3/types/src";
import {useAuthenticationElevate} from "../Modules/Modules";
export const redirectKey = "marvinRedirectTo"

export interface Redirect {
	pathname: string,
	whenReachable: boolean
}

function serialize(redirect: Redirect): string {
	return JSON.stringify(redirect)
}

function deserialize(redirect: string): Redirect {
	return JSON.parse(redirect)
}

export function saveRedirect(){
	_saveRedirect()
}

export function saveRedirectWhenReachable(module: string){
	_saveRedirect(module, true)
}

function _saveRedirect(pathname?: string, whenReachable: boolean = false){
	sessionStorage.setItem(
		redirectKey,
		serialize({
			pathname: pathname ?? (location.pathname + location.search),
			whenReachable
		})
	)
}

export function removeRedirect(){
	sessionStorage.removeItem(redirectKey)
}

export function getRedirect(): Redirect | false {
	const redirect = sessionStorage.getItem(redirectKey)
	return !!redirect && deserialize(redirect)
}

export function shouldRedirect(authModules: string[] = []){
	const redirect = getRedirect();

	if(!redirect)
		return false;

	if(!redirect.whenReachable || authModules.includes(redirect.pathname))
		return true;

	removeRedirect();
	return false;
}

export function redirect(push: History["push"]){
	const uri = getRedirect()
	removeRedirect();

	if(!!uri)
		push(uri)
}

export function Redirect(){
	const { push } = useHistory()

	useEffect(() => redirect(push), [])

	return <Spinner />
}

export function AuthenticationElevator({module}: {module: IModuleConfiguration}){
	const authenticationElevate = useAuthenticationElevate();

	useEffect(() => authenticationElevate(module), [module])

	return <Spinner />
}
