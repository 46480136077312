import {Box, Button, Container, Grid} from "@mui/material";
import {Outlined} from "../Container/Outlined";
import {saveRedirect} from "../Redirector/Redirect";
import {TranslatedText} from "../ui/TranslatedText/TranslatedText";
import {useIntl} from "react-intl";
import {useCallback} from "react";



function Authenticate() {
	const intl = useIntl();

	const loginB2X = useCallback(() => { saveRedirect(); window.location.href = configuration.b2xLoginUrl }, [configuration.b2xLoginUrl])
	const loginIDP = useCallback(() => { saveRedirect(); window.location.href = configuration.vwCloudIdpLoginUrl }, [configuration.vwCloudIdpLoginUrl])

	return (
		<Container maxWidth="md" disableGutters style={{paddingTop: "2rem"}}>
			<Box display='flex' flexDirection="column" gap="3rem">
				<Outlined header={intl.formatMessage({id: "marvin3Header"})}>
					<Grid container>
						<Grid item xs={12} sm={12} md={5.5}>
							<Button color='primary' variant='contained' size='small' fullWidth onClick={loginB2X}>
								<TranslatedText text="marvin3B2X"/>
							</Button>
						</Grid>
						<Grid item xs={0} sm={0} md={1} />
						<Grid item xs={12} sm={12} md={5.5}>
							<Button color='secondary' variant='contained' size='small' fullWidth onClick={loginIDP}>
								<TranslatedText text="marvin3VW"/>
							</Button>
						</Grid>
					</Grid>
				</Outlined>
				<Outlined header={intl.formatMessage({id: "marvin2Header"})}>
					<Grid container>
						<Grid item xs={12} sm={12} md={5.5}>
							<Button color='secondary' variant='outlined' size='small' fullWidth href='https://ws.skoda-auto.com/Marvin'>
								<TranslatedText text="marvin2B2X"/>
							</Button>
						</Grid>
						<Grid item xs={0} sm={0} md={1} />
						<Grid item xs={12} sm={12} md={5.5}>
							<Button color='secondary' variant='outlined' size='small' fullWidth href='https://ws.skoda-auto.com/MarvinI'>
								<TranslatedText text="marvin2BXInternal"/>
							</Button>
						</Grid>
					</Grid>
				</Outlined>
			</Box>
		</Container>
	)
}


export {Authenticate}
